<template>
  <div class="main-wrapper" style="margin-bottom: 0px" id="iss-form-template">
    <!--  这里是顶部控件 中间的控件全部都是根据添加内容状态属性来决定是否显示   -->
    <div v-for="item in questionnaireFields" :key="item.id">
      <div v-if="item.componentType === 'TOPCONTROL'">
        <!--          @click="selectedItem = item"  -->
        <div
          :class="{
            'banner-wrapper': true,
            'main-hover': true,
            select: handleSelectTwo(selectedItem, item.componentType),
            topControl: true,
          }"
        >
          <div class="topImg" v-if="item.logo">
            <!--      上传的Logo放在这里  -->
            <img :src="item.logo" class="img" />
          </div>
          <div class="topImg topImgShow" v-else>
            <img src="../../../../../assets/images/top.png" class="img" />
          </div>

          <div class="topTopic fs-18" v-if="item.topic">
            <a-form-item>
              <div>{{ item.topic }}</div>
            </a-form-item>
          </div>
          <div class="topTopic fs-18 topImgShow" v-else>
            <a-form-item>
              <div>主题内容</div>
            </a-form-item>
          </div>

          <div class="topBtn" id="iss-form-btn">
            <a-button type="primary" class="theme-color-btn btn">
              {{ item.buttonText }}
            </a-button>
            <!--            <a-button type="primary" class="theme-color-btn btn">-->
            <!--              更多活动-->
            <!--            </a-button>-->
          </div>

          <!--          <a-space-->
          <!--            class="item-operate"-->
          <!--            size="middle"-->
          <!--            v-if="handleSelectTwo(selectedItem, item.componentType)"-->
          <!--          >-->
          <!--            <a-tooltip title="删除">-->
          <!--              <a-button-->
          <!--                class="btn-delete"-->
          <!--                shape="circle"-->
          <!--                size="small"-->
          <!--                @click="handleClickByDelete($event, item)"-->
          <!--              >-->
          <!--                <template #icon>-->
          <!--                  <DeleteTwoTone twoToneColor="#f5222d" />-->
          <!--                </template>-->
          <!--              </a-button>-->
          <!--            </a-tooltip>-->
          <!--          </a-space>-->
        </div>
      </div>
    </div>

    <!--  banner  -->
    <!--    @click="
        selectedItem = {
          fieldCode: 'banner',
          componentType: 'BANNER',
          ...$store.state.common.bannerChart,
        }
      "
      -->
    <div
      :class="{
        'banner-wrapper': true,
        'main-hover': true,
      }"
    >
      <!--    有banner时显示banner, 没有则显示设置横幅图  -->
      <div
        v-if="
          $store.state.common.bannerChart.banner ||
          $store.state.common.bannerChart.bannerLink
        "
      >
        <img
          v-if="$store.state.common.bannerChart.banner"
          :src="$store.state.common.bannerChart.banner"
          style="width: 100%"
        />
        <div class="video-box" v-else>
          <div class="iss-video-box">
            <video
              ref="myVideo"
              id="video"
              class="iss-video"
              :poster="$store.state.common.bannerChart.videoBanner"
              controls
            >
              <source
                :src="$store.state.common.bannerChart.bannerLink"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>

      <div v-else class="banner-empty">
        <span>请在右侧设置横幅图属性</span>
        <ArrowRightOutlined class="ml-8" />
      </div>
    </div>
    <a-from class="main-form" layout="vertical">
      <!--   标题   -->
      <div
        :class="{
          'form-title': true,
          'main-hover': true,
          title: true,
        }"
      >
        <div class="fs-18 fw-500 mt-5 mb-5">
          {{ $store.state.common.questionnaire.activityTitle }}
        </div>
        <div class="fs-16">
          {{ $store.state.common.questionnaire.description }}
        </div>
      </div>
      <!--    活动时间  -->
      <div v-for="item in questionnaireFields" :key="item.id">
        <div
          :class="{
            'form-time': true,
            'main-hover': true,
            select: handleSelectTwo(selectedItem, item.componentType),
          }"
          v-if="item.componentType === 'TIME'"
        >
          <div class="fs-18 mb-5 time">
            <ClockCircleOutlined class="mr-5" />
            <span v-if="item.startTime || item.endTime">
              {{ item.startTime }}
              <span> {{ item.startTime ? '-' : '' }}</span>
              {{ item.endTime }}
            </span>
            <span v-else class="timeShow">举办时间</span>
          </div>
          <!--          <a-space-->
          <!--            class="item-operate"-->
          <!--            size="middle"-->
          <!--            v-if="handleSelectTwo(selectedItem, item.componentType)"-->
          <!--          >-->
          <!--            <a-tooltip title="删除">-->
          <!--              <a-button-->
          <!--                class="btn-delete"-->
          <!--                shape="circle"-->
          <!--                size="small"-->
          <!--                @click="handleClickByDelete($event, item)"-->
          <!--              >-->
          <!--                <template #icon>-->
          <!--                  <DeleteTwoTone twoToneColor="#f5222d" />-->
          <!--                </template>-->
          <!--              </a-button>-->
          <!--            </a-tooltip>-->
          <!--          </a-space>-->
        </div>
        <!--       地址  -->
        <div
          :class="{
            'form-address': true,
            'main-hover': true,
            select: handleSelectTwo(selectedItem, item.componentType),
          }"
          v-if="item.componentType === 'ADDRESS'"
        >
          <div class="fs-18 mb-5 address">
            <EnvironmentOutlined class="mr-5" />
            <span v-if="item.province || item.city || item.address">
              {{ item.province }}
              {{ item.city === item.province ? '' : item.city }}
              {{ item.address }}
            </span>
            <span v-else class="addressShow">活动地址</span>
          </div>
          <!--          <a-space-->
          <!--            class="item-operate"-->
          <!--            size="middle"-->
          <!--            v-if="handleSelectTwo(selectedItem, item.componentType)"-->
          <!--          >-->
          <!--            <a-tooltip title="删除">-->
          <!--              <a-button-->
          <!--                class="btn-delete"-->
          <!--                shape="circle"-->
          <!--                size="small"-->
          <!--                @click="handleClickByDelete($event, item)"-->
          <!--              >-->
          <!--                <template #icon>-->
          <!--                  <DeleteTwoTone twoToneColor="#f5222d" />-->
          <!--                </template>-->
          <!--              </a-button>-->
          <!--            </a-tooltip>-->
          <!--          </a-space>-->
        </div>
      </div>

      <draggable
        :list="questionnaireFields"
        tag="transition-group"
        :animation="500"
        :component-data="{ name: 'flip-list', type: 'transition' }"
        item-key="fieldCode"
      >
        <template #item="{ element }">
          <div
            :class="{
              'field-item': true,
              'main-hover': true,
            }"
          >
            <!--          'main-select': handleSelect(selectedItem, element),   -->
            <!--            富文本内容     -->
            <div v-if="element.componentType === 'TEXT'" class="textBox">
              <div v-if="element.fieldTips || element.editorValue">
                <div class="textTitle fs-18">{{ element.fieldTips }}</div>
                <!--             富文本内容用 v-html 来绑定    -->
                <div
                  class="textCjontent fs-18"
                  v-html="element.editorValue"
                ></div>
              </div>

              <div v-else class="textShow">
                <div class="textTitle fs-18">文本标题</div>
                <v-html class="textCjontent fs-18">
                  在右侧属性栏可编辑文本标题和内容
                </v-html>
              </div>
            </div>

            <!--             视频    -->
            <div v-else-if="element.componentType === 'VIDEO'" class="videoBox">
              <div>
                <div v-if="element.videoTitle" class="videoTitle">
                  {{ element.videoTitle }}
                </div>
                <div v-else class="videoTitle">视频</div>
              </div>

              <!--            点击设置属性后，监听属性设置输入的内容，来决定显示样式      -->

              <div
                class="video-box"
                v-if="element.videoLink || element.videoImage"
              >
                <div class="iss-video-box">
                  <video
                    ref="myVideo"
                    id="video"
                    class="iss-video"
                    :poster="element.videoImage"
                    controls
                  >
                    <source :src="element.videoLink" type="video/mp4" />
                  </video>
                </div>
              </div>

              <div class="iss-videoBac" v-else>
                <img src="../../../../../assets/images/videoIcon.png" />
                <div>
                  请在右侧设置视频属性<ArrowRightOutlined class="ml-8" />
                </div>
              </div>
            </div>

            <!--             图片   -->
            <div v-else-if="element.componentType === 'IMAGE'" class="imgBox">
              <!--            点击设置属性后，监听属性设置输入的内容，来决定显示样式      -->
              <div>
                <div v-if="element.imageTitle" class="videoTitle">
                  {{ element.imageTitle }}
                </div>
                <!--                <div v-else class="videoTitle">图片</div>-->
              </div>

              <img
                v-if="element.image"
                :src="element.image"
                class="designImg"
              />

              <div class="iss-videoBac" v-else>
                <div>
                  <img src="../../../../../assets/images/imgIcon.png" />
                </div>
                <div>
                  请在右侧设置图片属性<ArrowRightOutlined class="ml-8" />
                </div>
              </div>
            </div>

            <!--             议程   -->
            <div
              class="agenda fs-16"
              v-else-if="element.componentType === 'AGENDA'"
            >
              <div class="agendaTitle">
                {{ element.agendaTitle ? element.agendaTitle : '议程标题' }}
              </div>
              <div class="agendaShow">
                <div class="agendaName">
                  <div>
                    {{ element.agendaName ? element.agendaName : '议程名称' }}
                  </div>
                  <div class="iss-agendaDate">
                    {{ element.agendaDate ? element.agendaDate : '议程日期' }}
                  </div>
                </div>
                <div
                  v-if="element.questionAnswerVoList.length > 0"
                  class="timeStyle"
                >
                  <a-timeline>
                    <div
                      v-for="ele in element.questionAnswerVoList"
                      :key="ele.id"
                    >
                      <!--                      <a-descriptions size="middle">-->
                      <!--                      </a-descriptions>-->
                      <div class="agendaContent">
                        <div class="agendaTime">
                          <div v-if="ele?.startTime || ele?.endTime">
                            {{ ele.startTime }}
                            <span>
                              {{ ele.startTime ? '-' : '' }}
                            </span>
                            {{ ele.endTime }}
                          </div>
                          <div class="speakerNull" v-else>议程时间</div>
                        </div>
                        <!--        添加事件轴     -->
                        <a-timeline-item class="timelineItem" :key="ele.id">
                          <div class="description">
                            <!--                     v-for="e in ele.childGroup"
                              :key="e.id"
                                 -->
                            <div class="childClass">
                              <!--                          <div class="agendaTitle">-->
                              <!--                            {{ e.description ? e.description : '主题' }}-->
                              <!--                          </div>-->
                              <!--                          <div class="speaker" v-if="e.name">-->
                              <!--                            {{ e.name ? e.name : '主讲人' }}-->
                              <!--                          </div>-->
                              <!--                      议程主要内容     -->

                              <div
                                class="childGroupe-ditorValue"
                                v-html="ele.editorValue"
                                v-if="ele.editorValue"
                              ></div>

                              <div class="speakerNull" v-else>
                                <div class="agendaTitle">主题</div>
                                <div class="speaker">主讲人</div>
                              </div>
                            </div>
                          </div>
                        </a-timeline-item>
                      </div>
                    </div>
                  </a-timeline>
                </div>
                <div v-else class="agendaContent">
                  <div class="agendaTime">议程时间</div>
                  <div>
                    <div class="agendaTitle">主题</div>
                    <div class="speaker">主讲人</div>
                  </div>
                </div>
              </div>
            </div>

            <!--         嘉宾  guestsImage  -->

            <div
              class="anger fs-16"
              v-else-if="element.componentType === 'ANGER'"
            >
              <div class="angerTitle">
                {{ element.agendaTitle ? element.agendaTitle : '嘉宾' }}
              </div>
              <!--     && element.questionAnswerVoList.filter((item)=> item.guestsImage !== '')         -->
              <div
                class="angerStyle"
                v-if="element.questionAnswerVoList.length > 0"
              >
                <div
                  class="angerShow"
                  v-for="ele in element.questionAnswerVoList"
                  :key="ele.id"
                >
                  <div class="angerImage">
                    <img
                      v-if="ele.guestsImage"
                      class="angerImg"
                      :src="ele.guestsImage"
                      alt=""
                    />
                    <img
                      v-else
                      class="angerImg"
                      src="../../../../../assets/images/guestsImage.png"
                      alt=""
                    />
                    <!--         :class="ele.guestsImage ? 'angerContent' : 'angerContentNone'"             -->
                    <div class="angerContent">
                      <!--                      <div class="guestsName">-->
                      <!--                        {{ ele.guestsName ? ele.guestsName : '姓名' }}-->
                      <!--                      </div>-->
                      <div class="guestsBriefly">
                        <!--                        {{  ele.guestsBriefly ? ele.guestsBriefly : '简介' }}-->
                        <div
                          class="groupe-ditorValue"
                          v-html="ele.guestsBriefly"
                          v-if="ele.guestsBriefly"
                        ></div>
                        <div v-else>简介</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--            || element.questionAnswerVoList[0]?.guestsImage === ''   -->
              <div
                class="angerStyle"
                v-if="element.questionAnswerVoList.length === 0"
              >
                <div class="angerShow">
                  <div class="angerImage">
                    <img
                      class="angerImg"
                      src="../../../../../assets/images/guestsImage.png"
                      alt=""
                    />
                    <div class="angerContent">
                      <div class="guestsName">姓名</div>
                      <div class="guestsBriefly">简介</div>
                    </div>
                  </div>
                </div>
                <div class="angerShow">
                  <div class="angerImage">
                    <img
                      class="angerImg"
                      src="../../../../../assets/images/guestsImage.png"
                      alt=""
                    />
                    <div class="angerContent">
                      <div class="guestsName">姓名</div>
                      <div class="guestsBriefly">简介</div>
                    </div>
                  </div>
                </div>
                <div class="angerShow">
                  <div class="angerImage">
                    <img
                      class="angerImg"
                      src="../../../../../assets/images/guestsImage.png"
                      alt=""
                    />
                    <div class="angerContent">
                      <div class="guestsName">姓名</div>
                      <div class="guestsBriefly">简介</div>
                    </div>
                  </div>
                </div>
                <div class="angerShow">
                  <div class="angerImage">
                    <img
                      class="angerImg"
                      src="../../../../../assets/images/guestsImage.png"
                      alt=""
                    />
                    <div class="angerContent">
                      <div class="guestsName">姓名</div>
                      <div class="guestsBriefly">简介</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <a-space class="item-operate" size="middle">-->
            <!--              <a-tooltip title="删除">-->
            <!--                <a-button-->
            <!--                  class="btn-delete"-->
            <!--                  shape="circle"-->
            <!--                  size="small"-->
            <!--                  @click="handleClickByDelete($event, element)"-->
            <!--                >-->
            <!--                  <template #icon>-->
            <!--                    <DeleteTwoTone twoToneColor="#f5222d" />-->
            <!--                  </template>-->
            <!--                </a-button>-->
            <!--              </a-tooltip>-->
            <!--            </a-space>-->
          </div>
        </template>
      </draggable>
    </a-from>

    <div v-for="item in questionnaireFields" :key="item.id">
      <!--    表单    -->
      <div
        :class="{
          'main-btn': true,
          'mainForm-hover': true,
          select: handleSelectTwo(selectedItem, item.componentType),
        }"
        v-if="item.componentType === 'FORM'"
      >
        <div class="formTitle">
          {{ item.formTitle ? item.formTitle : '表单标题' }}
          <!--          <div class="errorTips">-->
          <!--            <div v-if="item.status === 2">-->
          <!--              该表单已过期，请修改表单开放时间或重新嵌入。-->
          <!--            </div>-->
          <!--            <div v-else-if="item.status === 3">嵌入的表单已被删除请重新嵌入</div>-->
          <!--            <div v-else-if="item.fieldCount === 0">-->
          <!--              该表单未发布，请先发布表单再重新嵌入。-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <!--     todo 表单字段的渲染位置   -->
        <div v-if="item.formMeeting">
          <div id="iframeStyle" :height="iframeStyleHeight"></div>
          <iframe
            ref="iframeRef"
            :key="`${item.formUrl}?btn=0&banner=0`"
            id="iframe"
            :src="`${item.formUrl}?btn=0&banner=0`"
            @load="adjustIframe"
            :style="{ height: formHeight }"
          >
          </iframe>
          <div id="iss-formBtn">
            <a-button class="theme-color-btnForm butStyleForm">
              {{ item.formBtnText ? item.formBtnText : '提交' }}
            </a-button>
          </div>
        </div>

        <div class="iss-form" v-else>
          <img src="../../../../../assets/images/videoIcon.png" />
          <div>请在右侧设置报名表单<ArrowRightOutlined class="ml-8" /></div>
        </div>

        <!--        <a-space-->
        <!--          class="item-operate"-->
        <!--          size="middle"-->
        <!--          v-if="handleSelectTwo(selectedItem, item.componentType)"-->
        <!--        >-->
        <!--          <a-tooltip title="删除">-->
        <!--            <a-button-->
        <!--              class="btn-delete"-->
        <!--              shape="circle"-->
        <!--              size="small"-->
        <!--              @click="handleClickByDelete($event, item)"-->
        <!--            >-->
        <!--              <template #icon>-->
        <!--                <DeleteTwoTone twoToneColor="#f5222d" />-->
        <!--              </template>-->
        <!--            </a-button>-->
        <!--          </a-tooltip>-->
        <!--        </a-space>-->
      </div>

      <!--    问卷  'main-hover': true,  -->
      <div
        :class="{
          'main-btn': true,
          'mainForm-hover': true,
          select: handleSelectTwo(selectedItem, item.componentType),
        }"
        v-if="item.componentType === 'QUESTIONNAIRE'"
      >
        <div class="qTitle">
          {{ item.qTitle ? item.qTitle : '问卷标题' }}
          <!--          <div class="errorTips">-->
          <!--            &lt;!&ndash;            <div v-if="item.status === 2">该问卷已过期，请重新选择问卷</div>&ndash;&gt;-->
          <!--            <div v-if="item.status === 3">嵌入的问卷已被删除请重新嵌入</div>-->
          <!--            <div v-if="item.questionnaireStatus === 'unpublished'">-->
          <!--              该问卷未发布，请先发布表单再重新嵌入。-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <!--     todo 表单字段的渲染位置   -->
        <div v-if="item.qMeeting">
          <iframe
            id="qIframe"
            :src="`${item.questionnaireUrl}?btn=0&banner=0`"
            @load="qIframe"
          >
          </iframe>
          <!--     todo 表单字段的渲染位置   -->
          <div id="iss-questionnaireBtn">
            <a-button
              class="theme-color-btnQuestionnaire butStyleQuestionnaire"
            >
              {{ item.qBtnText ? item.qBtnText : '提交' }}
            </a-button>
          </div>
        </div>

        <div class="iss-questionnaire" v-else>
          <img src="../../../../../assets/images/videoIcon.png" />
          <div>请在右侧设置调查问卷<ArrowRightOutlined class="ml-8" /></div>
        </div>

        <!--        <a-space-->
        <!--          class="item-operate"-->
        <!--          size="middle"-->
        <!--          v-if="handleSelectTwo(selectedItem, item.componentType)"-->
        <!--        >-->
        <!--          <a-tooltip title="删除">-->
        <!--            <a-button-->
        <!--              class="btn-delete"-->
        <!--              shape="circle"-->
        <!--              size="small"-->
        <!--              @click="handleClickByDelete($event, item)"-->
        <!--            >-->
        <!--              <template #icon>-->
        <!--                <DeleteTwoTone twoToneColor="#f5222d" />-->
        <!--              </template>-->
        <!--            </a-button>-->
        <!--          </a-tooltip>-->
        <!--        </a-space>-->
      </div>
    </div>
    <div v-for="item in questionnaireFields" :key="item.id">
      <div
        :class="{
          'main-btn': true,
          'main-hover': true,
          select: handleSelectTwo(selectedItem, item.componentType),
        }"
        v-if="item.componentType === 'BOTTOMCONTROL'"
      >
        <!--  样式二  -->
        <div
          :class="{
            'main-btn': true,
            'main-hover': true,
          }"
          class="iss-butStyle"
          v-if="item.showBut"
        >
          <div id="iss-butStyletShare">
            <a-button class="theme-color-btnShare butStyleShare">
              分享
            </a-button>
          </div>

          <div id="iss-butStyletTwo">
            <a-button type="primary" class="theme-color-btn butStyleTwo">
              {{ item.butStyleText ? item.butStyleText : '按钮' }}
            </a-button>
          </div>
        </div>

        <!-- 样式一   -->
        <a-button
          type="primary"
          v-else
          id="iss-themeStyleColor"
          class="theme-color-btn butStyleTwo"
        >
          {{ item.butStyleText ? item.butStyleText : '按钮' }}
        </a-button>
        <!--        <a-space-->
        <!--          class="item-operate"-->
        <!--          size="middle"-->
        <!--          v-if="handleSelectTwo(selectedItem, item.componentType)"-->
        <!--        >-->
        <!--          <a-tooltip title="删除">-->
        <!--            <a-button-->
        <!--              class="btn-delete"-->
        <!--              shape="circle"-->
        <!--              size="small"-->
        <!--              @click="handleClickByDelete($event, item)"-->
        <!--            >-->
        <!--              <template #icon>-->
        <!--                <DeleteTwoTone twoToneColor="#f5222d" />-->
        <!--              </template>-->
        <!--            </a-button>-->
        <!--          </a-tooltip>-->
        <!--        </a-space>-->
      </div>
    </div>
  </div>
</template>

<script>
// DeleteTwoTone
import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  // DeleteTwoTone,
} from '@ant-design/icons-vue';
import { Timeline } from 'ant-design-vue';
import draggable from 'vuedraggable'; //拖拽组件
import {
  // onUnmounted,
  // watch,
  ref,
  toRefs,
  reactive,
  computed,
} from 'vue';
import { useStore } from 'vuex';
// import { local } from "@/utils/storage";
// import { randoms } from "@/utils";
// import { session } from '@/utils/storage';

export default {
  name: 'designer',
  components: {
    ArrowRightOutlined,
    ClockCircleOutlined,
    EnvironmentOutlined,
    draggable,
    // DeleteTwoTone,
    // ATooltip: Tooltip,
    ATimeline: Timeline,
    // ADescriptionsItem: Descriptions.Item,
    ATimelineItem: Timeline.Item,
  },
  props: ['relationRef'],

  setup(props) {
    const store = useStore();
    const myVideo = ref();
    const iframeRef = ref();
    // const formHeight = local.get('formHeight');
    // const { campaignId } = session.get('update');
    const state = reactive({
      show: true,
      data: [],
      iframeStyleHeight: '',
      formHeight: '',
    });
    const selectedItem = computed({
      get: () => store.state.common.selectedItem,
      set: value => {
        props.relationRef
          .validate()
          .then(() => store.commit('common/setSelectedItem', value));
        store.commit('common/setStatus', true);
      },
    });
    // value => store.commit('common/setSelectedItem', value),
    // props.relationRef.validate()
    //   .then(() => store.commit('common/setSelectedItem', value));

    const questionnaireFields = computed({
      get: () => store.state.common.questionnaireFields,
      set: _value => {
        props.relationRef
          .validate()
          .then(() => store.commit('common/setQuestionnaireFields', _value));
      },
    });

    // watch(
    //   () => store.state.common.selectedItem,
    //   value => {
    //     props.relationRef
    //       .validate()
    //       .then(() => store.commit('common/setSelectedItem', value));
    //   }
    // );

    window.addEventListener(
      'message',
      e => {
        // 通过origin对消息进行过滤，避免遭到XSS攻击
        // if (e.origin === 'http://127.0.0.1:8002') {

        console.log('e.origin55', e.origin); // 子页面所在的域
        console.log('e.data55', e.data, e.data.formHeight); // 子页面发送的消息, hello, parent!\
        // 表单高度
        let ifm = document.getElementById('iframe');
        ifm.height = e.data.formHeight + 32 + 'px';
        state.formHeight = e.data.formHeight + 32 + 'px';

        // }
      },
      false
    );

    return {
      myVideo,
      ...toRefs(state),
      questionnaireFields,
      selectedItem,
      iframeRef,
      // formHeight,
      // formLink,
      // qLink,
      // campaignId,
      adjustIframe: () => {
        // let ifm = document.getElementById('iframe');
        // ifm.height = document.documentElement.scrollHeight + 'px';
        // console.log('height', ifm.height);
        // state.iframeStyleHeight = ifm.height;
      },
      qIframe: () => {
        let ifmEle = document.getElementById('qIframe');
        ifmEle.height = document.documentElement.scrollHeight;
      },

      handlePlay: () => {
        const video = document.getElementById('video');
        document.getElementById('poster').style.display = 'none';
        video.controls = true; // 控件的显示
        video.play();
      },
      // handleSelect: (selectedItem, element) => {
      //   let status = store.state.common.status;
      //   if (status) {
      //     // 拖拉拽
      //     if (element?.questionnaireId) {
      //       if (selectedItem?.id === element?.id) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     }
      //   }
      // },
      // 基础属性处理
      handleSelectTwo: (selectedItem, componentType) => {
        let status = store.state.common.status;
        if (status) {
          if (selectedItem?.componentType === componentType) {
            return true;
          } else {
            false;
          }
        }
      },

      handleSelectedItem: element => {
        store.commit('common/setSelectedItem', element);
        store.commit('common/setStatus', true);
      },

      handleClickByDelete: (e, data) => {
        e.stopImmediatePropagation();
        const fields = store.state.common.questionnaireFields;
        if (
          data.fieldName === '省' &&
          fields.findIndex(i => i.fieldName === '市') !== -1
        ) {
          fields.splice(
            fields.findIndex(i => i.fieldName === '市'),
            1
          );
        }
        fields.splice(
          fields.findIndex(i => i.fieldCode === data.fieldCode),
          1
        );
        store.commit('common/setSelectedItem', {});
      },
    };
  },
};
</script>

<style scoped lang="less">
.main-wrapper {
  margin: 60px auto 30px auto;
  padding-top: 10px;
  max-width: 620px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: @box-shadow-base;
  .banner-wrapper {
    position: relative;
    margin: 0 10px;
    .banner-empty {
      padding: 55px 0;
      text-align: center;
      background-color: hsla(0, 0%, 95.7%, 0.7);
      //margin-bottom: 24px;
    }
  }
  .single-line {
    display: block;
    margin: 0;
    white-space: break-spaces;
    word-break: break-all;
  }
  .main-hover {
    position: relative;
    border: 1px dashed transparent;
    transition: all 0.3s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
    //&:hover {
    //  background-color: @primary-3;
    //  cursor: pointer;
    //  .banner-empty {
    //    background-color: @primary-3;
    //  }
    //}
  }
  .mainForm-hover {
    position: relative;
    border: 1px dashed transparent;
    transition: all 0.3s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 95px;
      width: 88%;
      height: 81%;
      z-index: 20;
    }
    &:hover {
      //background-color: @primary-3;
      //cursor: pointer;
      .banner-empty {
        background-color: @primary-3;
      }
    }
  }
  .title {
    padding: 0px 50px;
  }
  .topControl {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96%;
    padding: 20px;
    .topImg {
      width: 15%;
    }
    .topImgShow {
      color: #999999;
    }
    .img {
      width: 32px;
      height: 32px;
    }
    .topTopic {
      width: 60%;
    }
    .topBtn {
      width: 18%;
    }
  }
  .select {
    //border-color: @primary-color;
    //background-color: @primary-3;
    transition: all 0.3s ease-in-out;
    position: relative;
    .banner-empty {
      background-color: @primary-3;
    }
    .item-operate {
      display: inline-flex !important;
    }
    .item-operate {
      position: absolute;
      right: 7px;
      top: 0px;
      z-index: 28;
      display: none;
    }
  }
  //.showDelete {
  //  position: relative;
  //  .delete-operate {
  //    position: absolute;
  //    right: 7px;
  //    top: 0px;
  //    z-index: 28;
  //    display: none;
  //  }
  //}

  .main-form {
    margin-top: 20px;

    .form-time {
      padding: 0 50px;
      .time {
        margin: 15px 0px;
      }
      .timeShow {
        color: #999999;
      }
    }
    .form-address {
      padding: 0 50px;
      .address {
        margin: 15px 0px;
      }
      .addressShow {
        color: #999999;
      }
    }
    .textTitle {
      margin: 15px 0px;
    }
    .textCjontent {
      margin: 15px 0px;
      overflow: auto;
    }
    .childGroupe-ditorValue {
      overflow: auto;
      :deep(p) {
        margin-bottom: 0px;
      }
    }
    .groupe-ditorValue {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      :deep(p) {
        margin-bottom: 0px;
      }
    }
    .guestsName {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .field-item {
      position: relative;
      .item-operate {
        position: absolute;
        right: 7px;
        top: 0px;
        z-index: 28;
        display: none;
      }
    }

    .ant-form-item {
      padding: 15px 50px;
      margin-bottom: 0;
    }
    .main-select {
      border-color: @primary-color;
      background-color: @primary-3;
      transition: all 0.3s ease-in-out;
      .banner-empty {
        background-color: @primary-3;
      }
      .item-operate {
        display: inline-flex !important;
      }
    }
  }

  .main-agreement {
    margin: 20px 50px 10px 50px;
    text-align: center;
    font-size: 12px;
    color: @text-color-secondary;
    .ant-checkbox-wrapper {
      font-size: 12px;
      color: @text-color-secondary;
    }
    &.main-hover:hover {
      background-color: transparent;
    }
  }
  .main-btn {
    padding: 30px 0 40px 0;
    text-align: center;
    .formTitle {
      text-align: left;
      padding: 15px 50px 0px;
      margin-bottom: 30px;
    }
    .iss-form iss-questionnaire {
      padding: 55px 0;
      text-align: center;
      background-color: hsla(0, 0%, 95.7%, 0.7);
      margin-bottom: 24px;
      .img {
        object-fit: cover;
        width: 32px;
        height: 32px;
      }
    }
    .iss-butStyle {
      .ant-btn {
        padding: 0 110px;
      }
    }
    .butStyleTwo {
      padding: 0 110px;
    }
  }
}
.imgBox {
  //width: 519px;
  padding: 40px 50px 15px;
  .iss-videoBac {
    padding: 55px 0;
    text-align: center;
    background-color: hsla(0, 0%, 95.7%, 0.7);
    margin-bottom: 24px;
    .img {
      object-fit: cover;
      width: 32px;
      height: 32px;
    }
  }
  .designImg {
    width: 100%;
    object-fit: cover;
  }
  .videoTitle {
    padding: 0px 0px 15px 15px;
  }
}
.videoBox {
  //width: 519px;
  padding: 0 50px;
  .videoTitle {
    padding: 15px 0px 15px 15px;
  }
  .iss-videoBac {
    padding: 55px 0;
    text-align: center;
    background-color: hsla(0, 0%, 95.7%, 0.7);
    margin-bottom: 24px;
    .img {
      object-fit: cover;
      width: 32px;
      height: 32px;
    }
  }
}
.textBox {
  //width: 519px;
  padding: 0 50px 15px;
  .textShow {
    color: #999999;
  }
}
.video-box {
  width: 100%;
  position: relative;
  //margin: 0 16px;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 20px;
  .iss-video-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .iss-video {
      width: 100%;
      height: 100%;
    }
  }
}

.agenda {
  padding: 15px 50px 0px;
  .agendaShow {
    color: #999999;
  }
  .agendaTitle {
    color: #999999;
    //padding-top: 15px;
  }
  .agendaName {
    background: #f7f8fa;
    padding: 15px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    .iss-agendaDate {
      text-align: right;
      width: 24%;
    }
  }
  .agendaContent {
    display: flex;
    //align-items: center;
    //justify-content: space-between;
    padding: 0px 15px;

    .agendaTime {
      //width: 15%;
      width: 96px;
      margin-top: -6px;
    }
    .timelineItem {
      width: 85%;
    }

    .description {
      padding-top: 1px;
      width: 100%;
      padding-left: 8px;
      .agendaTitle {
        width: 100%;
      }
      .speaker {
        width: 100%;
      }
    }
  }
}

.anger {
  padding: 15px 50px 0px;
  .angerShow {
    color: #999999;
    display: flex;
    justify-content: space-between;
    width: 25%;
  }
  .angerImage {
    position: relative;
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    //width: 100%;
  }
  .guestsName {
    font-size: 14px;
  }
  .angerTitle {
    color: #999999;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .angerStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .angerImg {
    width: 82%;
  }
  .angerContent {
    position: absolute;
    //top: 105px;
    bottom: -110px;
    left: -14px;
    width: 109px;
    height: 126px;
    background: linear-gradient(124deg, #eeeeee 0%, #ffffff 85%);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    font-size: 12px;
    padding: 5px;
    margin-bottom: 9px;
  }
  .angerContentNone {
    //display: none;
  }
}
</style>

<style scoped lang="less">
#iss-form-template {
  --primary-color: #5979f8;
  --color: #ffffff;
}
#iss-form-template :deep(.theme-color-text),
#iss-form-template .theme-color .ant-btn-background-ghost.ant-btn-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.theme-color-btn {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--color);
}
.butStyleTwo {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--color);
}

.theme-color-btnShare {
  background-color: var(--color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.butStyleShare {
  background-color: var(--color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
// 表单
.theme-color-btnForm {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--color);
}
.butStyleForm {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--color);
}
// 问卷
.theme-color-btnQuestionnaire {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--color);
}
.butStyleQuestionnaire {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--color);
}
.iss-butStyle {
  display: flex;
  justify-content: space-around;
}
// 表单
#iframe {
  width: 85%;
  border: none;
  //height: calc(100vh - 90px);
  //overflow-y: auto;
}
#iframeStyle {
  //width: 89%;
  //height: 830px;
  //position: absolute;
  //z-index: 1;
  //top: 109px;
  //background: red;
}
.form-main {
  padding: 0px;
}
#iss-formBtn {
  margin-top: 24px;
}
.butStyleForm {
  padding: 0px 50px;
}
// 问卷
.qTitle {
  text-align: left;
  padding: 15px 50px 0px;
  margin-bottom: 30px;
}

#qIframe {
  width: 85%;
}
#iss-questionnaireBtn {
  margin-top: 24px;
}
.butStyleQuestionnaire {
  padding: 0px 50px;
}
.errorTips {
  color: #f5222d;
}
.timeStyle {
  padding: 15px 0px;
}

.speakerNull {
  padding-bottom: 15px !important;
}
// 时间轴样式
.agenda /deep/ .ant-timeline-item-head {
  background-color: #d8d8d8;
  border: none;
}
.agenda /deep/ .ant-timeline-item-tail {
  border-left: 2px dashed #d8d8d8;
}
.agenda /deep/ .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}

// 手机预览样式
.mobile {
  .agenda {
    padding: 15px 12px 0px !important;
  }

  .iss-agendaDate {
    width: 32% !important;
  }

  .agendaTime {
    //width: 18%;
    //width: 44% !important;
    width: 119px !important;
  }
  .timelineItem {
    width: 65%;
  }
  .imgBox {
    padding: 40px 12px 15px;
  }
  .agendaTitle {
    padding: 0px 0px 15px 15px;
  }
  #iframe {
    width: 94%;
  }
  .anger {
    padding: 15px 27px 0px;
  }

  .videoBox {
    padding: 0 12px;
  }
  .title {
    padding: 0px 12px;
  }

  .main-wrapper {
    max-height: 661px;
    box-shadow: none;
  }
  .formTitle {
    padding: 15px 30px 0px !important;
    margin-bottom: 15px !important;
  }
}
</style>
